import { Button } from "@mantine/core";
import React, { useState } from "react";
import styles from "./Section2.module.scss";
import { useNavigate } from "react-router-dom";

function Section2() {
  const [state, setState] = useState(false);
   const navigate = useNavigate();

   const handleAgreementsManagerClick = () => {
    // console.log('press');
    window.open("/app/agreements_manager/", "_blank");
   };

  //  const handleAgreementsManagerClick2 = () => {
  //   console.log('press');
  //    navigate("/app/agreements_manager/notifications");
  //  };
  const handleObligationsManagerClick = ()=>{
    window.open("https://obligationapp.truecounsel.in/login", "_blank");
  }
   const handleNotificationsClick = () => {
     navigate("/app/agreements_manager/notifications");
   };
   const handleRecoveriesManagerClick = ()=>{
    window.open("https://recoveryapp.truecounsel.in/app", "_blank");
   }
   const handleDocumentsManagerClick = ()=>{
    window.open("/app/corporatedocuments", "_blank")
   }
  return (
    <div>
      <div>
        <div className={`mb-10 ${styles.sectionHeading}`}>
          manage your agreements and disputes with ease
        </div>
        <div
          className={`mt-3 flex flex-row justify-center items-center ${styles.buttonContainer}`}
        >
          <div
            style={{
              // border: "2px solid red",
              position: "relative",
            }}
          >
            <Button
              className={`${styles.buttonCommon}`}
              variant="outline"
              onClick={handleAgreementsManagerClick}
              
              style={
                !state
                  ? { background: "#46BDE1", color: "white" }
                  : { background: "white", color: "#46BDE1" }
              }
            >
              agreements manager
            </Button>
            
          </div>
          <Button
            className={`${styles.buttonCommon}`}
            variant="outline"
            onClick={handleObligationsManagerClick}
            
            style={
              !state
                ? { background: "#46BDE1", color: "white" }
                : { background: "white", color: "#46BDE1" }
            }
            >
              obligations manager
            </Button>
            <Button
            className={`${styles.buttonCommon}`}
            variant="outline"
            onClick={handleRecoveriesManagerClick}
            
            style={
              !state
                ? { background: "#46BDE1", color: "white" }
                : { background: "white", color: "#46BDE1" }
            }
            >
              recoveries manager
            </Button>
            <Button
            className={`${styles.buttonCommon}`}
            variant="outline"
            onClick={handleDocumentsManagerClick}
            
            style={
              !state
                ? { background: "#46BDE1", color: "white" }
                : { background: "white", color: "#46BDE1" }
            }
            >
              documents manager
            </Button>
          <Button
            className={`${styles.buttonCommon} `}
            variant="outline"
            style={{
              background: "white",
              color: "#46BDE1",
              border: "2px solid #46BDE1",
              
            }}
            disabled
          >
            <div style={{width: "auto", display: "flex", flexDirection: "column" }}>
              <div style={{ display: "block", textAlign: "center" }}>
                disputes manager
              </div>
              <div
                style={{
                  display: "block",
                  fontSize: "15px",
                  textAlign: "center",
                  fontFamily: "sans-serif"
                }}
              >
                coming soon
              </div>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
}
export default Section2;
